import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

const styles = {
    tableWrapper: {
        maxWidth: '89vw',
        position: 'relative',
        fontSize: '11px',
        fontWeight: 500

    },
    tableContainer: {
        width: '100%',
        border: '1px solid #ddd',
        position: 'relative',
        display: 'block',
    },
    headerRow: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 2,
        borderBottom: '2px solid #ddd',
    },
    headerCell: {
        padding: '2px',
        flexWrap: 'nowrap',
    },
    bodyContainer: {
        padding: '2px',
    },
    dataRow: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
    },
    dataCell: {
        padding: '2px',
        border: '1px solid #ddd',
        width: '100%',
        textAlign: 'left',
        flexWrap: 'nowrap',
    }



}

const getReactFragmentTextLength = (RF) => {
    console.log(RF)
    if (!RF.props.children)
        return 0
    if (['string', 'number'].includes(typeof RF.props.children))
        getReactFragmentTextLength(RF.props.children)
    return RF.props.children.length
}
const getMaxWidth = (headers, data, factorValue) => {
    let cellsWidth = {}
    headers.forEach((key) => {
        if (!cellsWidth[key]) {
            cellsWidth[key] = key.length * factorValue
        }
    })
    data.length && data.reduce((acc, x) => {
        Object.keys(x).forEach((key) => {
            if (!x[key]) {
                return
            }

            // console.log(x[key], x[key] instanceof HTMLElement)
            if (x[key] instanceof HTMLElement) {
                acc[key] = x[key].outerHTML.length * factorValue;
                return
            }
            if (React.isValidElement(x[key])) {
                if (x[key].props.children.length * factorValue > acc[key]) {
                    console.log(x[key].props.children.length, acc[key])
                    acc[key] = x[key].props.children.length * factorValue
                    return
                }
            }
            if (!acc[key]) {
                acc[key] = x[key].length * factorValue;
                return
            }
            if (x[key].length * factorValue > acc[key])
                acc[key] = x[key].length * factorValue;
        })
        return acc
    }, cellsWidth)
    cellsWidth.rowWidth = Object.values(cellsWidth).reduce((acc, x) => acc + x ,0)
    return cellsWidth
}
export const FlatTable = ({ headers = [], data = [], factorValue = 8 }) => {
    const cellsWidth = getMaxWidth(headers, data, factorValue)
    return (
        <div style={styles.tableWrapper}>
            <div style={styles.tableContainer}>
                <div style={{ ...styles.headerRow, width: `${cellsWidth.rowWidth}px`}}>
                    {headers.map((item, index) => (
                        <div key={index} style={{...styles.headerCell, width: `${cellsWidth[item]}px`}}>{item}</div>
                    ))}
                </div>
                <div style={{ ...styles.bodyContainer, width: `${cellsWidth.rowWidth}px`}}>
                    {data.map((row, rowIndex) => (
                        <div key={rowIndex} style={{...styles.dataRow}} role="row">
                            {headers.map((header, idx) => {
                                if (React.isValidElement(row[header]))
                                    return (
                                        <div key={`${rowIndex}-${idx}`} style={{ ...styles.dataCell, width: `${cellsWidth[header]}px` }} role="cell">
                                            <React.Fragment key={idx}>{row[header]}</React.Fragment>
                                        </div>
                                    )
                                if (row[header] instanceof HTMLElement)
                                    return <div key={idx} dangerouslySetInnerHTML={{ __html: row[header].outerHTML }} />;
                                if (typeof row[header] === 'object' && row[header])
                                    return (
                                        <Link key={`${rowIndex}-${idx}`}
                                              style={{ ...styles.dataCell, width: `${cellsWidth[header]}px` }} role="cell" to={row[header].url}>
                                            {row[header].value}
                                        </Link>
                                    )
                                return (
                                    <div key={`${rowIndex}-${idx}`}
                                         style={{ ...styles.dataCell, width: `${cellsWidth[header]}px` }} role="cell">
                                        {row[header]}
                                    </div>
                                )
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};