import React, { useState, useEffect, useContext, useRef } from 'react'
import { NavigationContext } from '../../../context/navigation-context'
// import { LangContext } from '../../context/lang-context'
import useForm from '../../../hooks/form-hook'
import useFetch from '../../../hooks/fetchMSSQL-hook'
import useFilter from '../../../hooks/filter-hook'
import { Link } from 'react-router-dom'
import SearchInput from '../../../components/SearchInput/SearchInput'
import NoResults from '../../../components/NoResults/NoResults'
import TerminalForm from '../../../components/Modals/TerminalForm'
import filterItems from '../../../util/filterItems'
import tmobileIcon from '../../../assets/images/tmobile_icon.png'
import plusIcon from '../../../assets/images/plus_icon.png'
import moment from "moment";
import FilterTrx from '../../../components/Filter/FilterTrx'
import Pagination from '../../../components/Pagination/Pagination'
import writeXlsxFile from 'write-excel-file'
import { NotificationManager } from 'react-notifications'

import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import { LangContext } from '../../../context/lang-context'
import axios from "axios"
import { API_URL } from '../../../config/config'
import { NotificationContext } from '../../../context/notification-context'
import ReportBtn from '../../../components/Buttons/ReportsBtn/ReportBtn'
import { LoaderContext } from '../../../context/loader-context'
import PaginationEx from '../../../components/Pagination/PaginationEx'
import tags from '../../ClientView/Tags/Tags'
import { getSheet } from '../../../util/getSheets'
import { FlatTable } from '../../../components/FlatTable/FlatTable'


const styles = {
  control: {
    display: 'grid',
    gridTemplateColumns: '4fr 2fr 4fr',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'start',
  },
  reportContainer: {
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'row-reverse',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',

  }
}

const Trxs = () => {
  const {showLoader, incrementRequests, decrementRequests} = useContext(LoaderContext)
  const { TRL_Pack } = useContext(LangContext)
  const { fetchMssqlApi } = useFetch()
  const { setHeaderData } = useContext(NavigationContext)
  const { ErrorNotification, SuccessNofication } = useContext(NotificationContext)
  const [rep76, setRep76] = useState(false)
  const [rep83, setRep83] = useState(false)
  const [rep84, setRep84] = useState(false)
  const [rep85, setRep85] = useState(false)
  const [rep97, setRep97] = useState(false)
  const [sumByPaymentType, setSumByPaymentType] = useState(false)
  const [repSum, setRepSum] = useState(false)
  const [repList, setRepList] = useState(false)
  const [recordsetLength, setRecordsetLength] = useState(0)
  const [filterIsDefault, setFilterIsDefault] = useState(true)
  const [isFlatTable, setIsFlatTable] = useState(false)
  const [summaryInfo, setSummaryInfo] = useState({})

  const [trxs, setTrxs] = useState(null);
  const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }));
  const handleSwitchPage = () => pageNo => {
    document.querySelector("section").scroll(0, 0)
    setFilter(prev => ({ ...prev, page: pageNo }))
    incrementRequests()
    let isPrevPage = false

    const columns = filter.columns
    for (let a in columns) {
      if (columns[a].searchbar == undefined) {
        columns[a].searchbar = null
      }
    }

    let terminalSerialNo = null;
    if (JSON.parse(columns[0].TerminalSerialNo != '') && JSON.parse(columns[0].TerminalSerialNo != null))
      terminalSerialNo = columns[0].TerminalSerialNo;

    let machineId = null;
    if (JSON.parse(columns[1].MachineId != '') && JSON.parse(columns[1].MachineId != null))
      machineId = columns[1].MachineId;

    let terminalTypeId = null;
    if (JSON.parse(columns[2].TerminalTypeId != '') && JSON.parse(columns[2].TerminalTypeId != null))
      terminalTypeId = columns[2].TerminalTypeId;

    let paymentTypeId = null;
    if (JSON.parse(columns[3].PaymentTypeId != '') && JSON.parse(columns[3].PaymentTypeId != null))
      paymentTypeId = columns[3].PaymentTypeId;

    let productNo = null;
    if (JSON.parse(columns[4].ProductNo != '') && JSON.parse(columns[4].ProductNo != null))
      productNo = columns[4].ProductNo;

    let price = null;
    if (JSON.parse(columns[5].Price != '') && JSON.parse(columns[5].Price != null))
      price = columns[5].Price;

    //set default create from / to date time
    let fromCreateDateTime = moment().format('YYYY-MM-DDT00:00:00');
    let toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59');

    if (JSON.parse(columns[6].dateTimeRangeFrom != '') && JSON.parse(columns[6].dateTimeRangeFrom != null))
        // fromCreateDateTime = lastTrxDatetime ? getCorrectTime(lastTrxDatetime, "search") : getCorrectTime(columns[6].dateTimeRangeFrom, "search");
      fromCreateDateTime = moment(columns[6].dateTimeRangeFrom).format('YYYY-MM-DDTHH:mm:ssZ');
    toCreateDateTime = moment(columns[6].dateTimeRangeTo).format('YYYY-MM-DDTHH:mm:ssZ');
    // if (filter.page > pageNo && pageNo != 1) {
    //   const lastTrx = trxs && trxs[0]
    //   isPrevPage = true
    //   fromCreateDateTime = moment(lastTrx.CreateDateTime).format('YYYY-MM-DDTHH:mm:ssZ') // add 1 ms to filter last transaction from prev page
    // }

    // // fromCreateDateTime = new Date(columns[6].dateTimeRangeFrom).toISOString()
    // if (JSON.parse(columns[6].dateTimeRangeTo != '') && JSON.parse(columns[6].dateTimeRangeTo != null))
    // if (filter.page < pageNo && pageNo != 1) {
    //   const lastTrx = trxs && trxs.at(-1)
    //   toCreateDateTime = moment(lastTrx.CreateDateTime).format('YYYY-MM-DDTHH:mm:ssZ')
    // }
    // toCreateDateTime = new Date(columns[6].dateTimeRangeTo).toISOString()

    getTrxs(terminalSerialNo, machineId, terminalTypeId, paymentTypeId, productNo, price, fromCreateDateTime, toCreateDateTime, pageNo, filter.rowsPerPage, false ,isPrevPage);
  };

  const { form, openForm, closeForm } = useForm()

  const defaultFilter = {
    showIndexes: false,
    page: 1,
    disableIndexes: true,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 25, 50, 100, 1000],
    visible: false,
    //sortByColumns: true,
    sortByColumns: false,
    //sortBy: '3 | asc | text',
    activeTags: [],
    columns: [
      {
        id: 1,
        name: 'Terminal',
        searchable: true,
        sortable: true,
        type: 'lookup',
        TerminalId: null,
        searchbar: null
      },
      {
        id: 2,
        name: 'Maszyna',
        searchable: true,
        sortable: true,
        type: 'lookup',
        MachineId: null,
        searchbar: null
      },
      {
        id: 3,
        name: 'Typ terminala',
        searchable: true,
        sortable: true,
        type: 'lookup',
        TerminalTypeId: null,
        searchbar: null
      },
      {
        id: 4,
        name: 'Typ płatności',
        searchable: true,
        sortable: true,
        type: 'lookup',
        PaymentTypeId: null,
        searchbar: null
      },
      {
        id: 5,
        name: 'Nr wyboru',
        searchable: true,
        sortable: true,
        type: 'text',
        ProductNo: null,
        searchbar: null
      },
      {
        id: 6,
        name: 'Cena',
        searchable: true,
        sortable: true,
        type: 'text',
        Price: null,
        searchbar: null
      },
      {
        id: 7,
        name: 'Zakres czasowy',
        searchable: false,
        sortable: false,
        type: 'datetimeRange',
        dateTimeRangeFrom: moment().format('YYYY-MM-DDT00:00:00'),
        dateTimeRangeTo: moment().format('YYYY-MM-DDT23:59:59')
      }
    ]
  }
  const getIsFilterDefault = () => {
    if (!filter) return true
    const defaultFilterAsString = JSON.stringify(defaultFilter.columns)
    const filterAsAString = JSON.stringify(filter.columns)
    if (defaultFilterAsString === filterAsAString) return true
    return false
  }
  const getAdditionalHoursByOffset = (time, isUTC) => (isUTC ? -1 : 1) * (time.getTimezoneOffset()) / 60
  const isWinterOffset = () => new Date().getTimezoneOffset() === -60 ? 0 : 1
  const getCurrentAdditionalHoursByTheClient = (datetime, additionalHour = 0, isUTC=false) => {
    let time = datetime
    if (time.getFullYear() <= 1970) {
      throw Error('can`t parse date')
    }
    if (window.location.host !== "portal.retail24.pl") {
      /**
       * difference between local server and "portal.retail24.pl" is 1 or 2 hours dependence by the offset
       * (tested on BAL (01.04.2024 and 05.03.2024))
       */
      // console.log(moment(time).add(getAdditionalHoursByOffset(time, isUTC) + additionalHour + getAdditionalHoursByOffset(new Date(), true), "h").format('YYYY-MM-DDTHH:mm:ss'))
      return moment(time).add(getAdditionalHoursByOffset(time, isUTC) + additionalHour + getAdditionalHoursByOffset(new Date(), true), "h").format('YYYY-MM-DDTHH:mm:ss')
    }
    return moment(time).add(getAdditionalHoursByOffset(time, isUTC) + additionalHour, "h").format('YYYY-MM-DDTHH:mm:ss')
  }
  const getCorrectTime = (datetime, options = null) => {
    let time = new Date(datetime)
    if (time.getFullYear() <= 1970) {
      throw Error('can`t parse date')
    }
    const timezoneOffset = time.getTimezoneOffset()
    if (timezoneOffset === -60) {
      switch (options) {
        case "DZ85":
        case "DZ84":
        case "byPaymentType":
        case "search":
          return getCurrentAdditionalHoursByTheClient(time, 0, true)
        case "list":
        case "summary":
          return getCurrentAdditionalHoursByTheClient(time, -2)
        default:
          return getCurrentAdditionalHoursByTheClient(time)
      }
    } else {
      switch (options) {
        case "DZ83":
        case "DZ76":
          return getCurrentAdditionalHoursByTheClient(time, 2)
        case "byPaymentType":
          return getCurrentAdditionalHoursByTheClient(time)
        case "DZ85":
        case "DZ84":
          return getCurrentAdditionalHoursByTheClient(time, 0, true)
        case "search":
          return moment(time).format("YYYY-MM-DD HH:mm:ss")
        default:
          return getCurrentAdditionalHoursByTheClient(time)
      }
    }
  }
  const [filter, setFilter] = useState(() => {
    if (localStorage.getItem('trxsFilter')) {
      const filter = JSON.parse(localStorage.getItem('trxsFilter'))
      filter.page = 1
      filter.columns[6].dateTimeRangeFrom = moment().format('YYYY-MM-DDT00:00:00')
      filter.columns[6].dateTimeRangeTo = moment().format('YYYY-MM-DDT23:59:59')
      return filter;
    }
    return defaultFilter;
  })

  const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
  const resetFilter = () => {
    setFilter(defaultFilter);
    incrementRequests()
    getTrxs(null, null, null, null, null, null, moment().format('YYYY-MM-DDT00:00:00'), moment().format('YYYY-MM-DDT23:59:59'), 1, defaultFilter.rowsPerPage);
  }

  const [clients, setClients] = useState([])
  const getClients = () => {
    if (localStorage.getItem('clientId') === "console") {
      fetchMssqlApi('clients', {}, clients => setClients(clients)
      )} else {
      setClients([])
    }
  }

  const exportList = () => {
    let body
    try {
      body = getReqBody()
    } catch (e) {
      NotificationManager.error(e)
      return
    }
    getSheet({
      callbackToChangeState: setRepList,
      url: `${API_URL}/api/reports/trxs`,
      name: `Transakcje_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`,
      body: {
        reportId: -10000,
        ...body,
      },
      spinnerTriggerState: true,
    })
  }

  const exportSumByPaymentType = () => {
    let body
    try {
      body = getReqBody()
    } catch (e) {
      NotificationManager.error(e)
      return
    }
    getSheet({
      callbackToChangeState: setSumByPaymentType,
      url: `${API_URL}/api/reports/trxs`,
      name: `Transakcje_wg_typu_platnosci_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`,
      body: {
        reportId: -10002,
        ...body,
      },
      spinnerTriggerState: true,
    })
  }
  const exportSum = () => {
    let body
    try {
      body = getReqBody()
    } catch (e) {
      NotificationManager.error(e)
      return
    }
    getSheet({
      callbackToChangeState: setRepSum,
      url: `${API_URL}/api/reports/trxs`,
      name: `Transakcje_wg_automatow_produktow_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`,
      body: {
        reportId: -10001,
        ...body,
      },
      spinnerTriggerState: true,
    })
  }

  const export76 = () => {
    let body
    try {
      body = getReqBody()
    } catch (e) {
      NotificationManager.error(e)
    }
    getSheet({
      callbackToChangeState: setRep76,
      url: `${API_URL}/api/reports/trxs`,
      name: `DZ76_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`,
      body: {
        reportId: 76,
        ...body,
      },
      spinnerTriggerState: true,
    })
  }
  const export83 = () => {
    let body
    try {
      body = getReqBody()
    } catch (e) {
      NotificationManager.error(e)
    }
    getSheet({
      callbackToChangeState: setRep83,
      url: `${API_URL}/api/reports/trxs`,
      name: `DZ83_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`,
      body: {
        reportId: 83,
        ...body,
      },
      spinnerTriggerState: true,
    })
  }

  const export84 = () => {
    const f = getReqBody()
    getSheet({
      callbackToChangeState: setRep84,
      url: `${API_URL}/api/reports/trxs`,
      name: `DZ84_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`,
      body: {
        reportId: 84,
        ...f,
      },
      spinnerTriggerState: true,
    })
  }
  const getReqBody = () => {
    const columns = filter.columns

    for (let a in columns) {
      if (columns[a].searchbar == undefined) {
        columns[a].searchbar = null
      }
    }
    let   terminalId = null
        , machineId = null
        , terminalTypeId = null
        , paymentTypeId = null
        , productNo = null
        , price = null
        , fromCreateDateTime = moment().format('YYYY-MM-DDT00:00:00')
        , toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59')
        , machineTags = null

    if (JSON.parse(columns[0].TerminalId != '') && JSON.parse(columns[0].TerminalId != null))
      terminalId = columns[0].TerminalId;

    if (JSON.parse(columns[1].MachineId != '') && JSON.parse(columns[1].MachineId != null))
      machineId = columns[1].MachineId;

    if (JSON.parse(columns[2].TerminalTypeId != '') && JSON.parse(columns[2].TerminalTypeId != null))
      terminalTypeId = columns[2].TerminalTypeId;

    if (JSON.parse(columns[3].PaymentTypeId != '') && JSON.parse(columns[3].PaymentTypeId != null))
      paymentTypeId = columns[3].PaymentTypeId;

    if (JSON.parse(columns[4].ProductNo != '') && JSON.parse(columns[4].ProductNo != null))
      productNo = columns[4].ProductNo;

    if (JSON.parse(columns[5].Price != '') && JSON.parse(columns[5].Price != null))
      price = columns[5].Price;


    if (JSON.parse(columns[6].dateTimeRangeFrom != '') && JSON.parse(columns[6].dateTimeRangeFrom != null))
      fromCreateDateTime = moment(columns[6].dateTimeRangeFrom).format('YYYY-MM-DDTHH:mm:ssZ');

    if (JSON.parse(columns[6].dateTimeRangeTo != '') && JSON.parse(columns[6].dateTimeRangeTo != null))
      toCreateDateTime = moment(columns[6].dateTimeRangeTo).format('YYYY-MM-DDTHH:mm:ssZ');
    if (!moment(fromCreateDateTime).isValid() || !moment(toCreateDateTime).isValid()) {
      NotificationManager.error("Wybierz poprawną datę")
      setRep84(false)
      throw Error("Wybierz poprawną datę")
    }
    try {
      machineTags = filter.activeTags
    } catch (e) {
      throw Error("Coś poszło nie tak")
    }
    return {
      terminalId,
      machineId,
      terminalTypeId,
      paymentTypeId,
      productNo,
      price,
      fromCreateDateTime,
      toCreateDateTime,
      machineTags,
    }
  }
  const export97 = () => {
    const f = getReqBody()
    getSheet({
      callbackToChangeState: setRep97,
      url: `${API_URL}/api/reports/trxs`,
      name: `DZ97_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`,
      body: {
        reportId: 97,
        ...f,
      },
      spinnerTriggerState: true,
    })
  }

  const export85 = () => {
    let body
    try {
      body = getReqBody()
    } catch (e) {
      NotificationManager.error(e)
    }
    getSheet({
      callbackToChangeState: setRep85,
      url: `${API_URL}/api/reports/trxs`,
      name: `DZ85_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`,
      body: {
        reportId: 85,
        ...body,
      },
      spinnerTriggerState: true,
    })
  }

  const search = (pageChanged = false) => {
    //TODO: unhardcode column idx, make it configurable
    const columns = filter.columns

    incrementRequests()
    for (let a in columns) {
      if (columns[a].searchbar == undefined) {
        columns[a].searchbar = null
      }
    }

    let terminalSerialNo = null;
    if (JSON.parse(columns[0].TerminalSerialNo != '') && JSON.parse(columns[0].TerminalSerialNo != null))
      terminalSerialNo = columns[0].TerminalSerialNo;

    let machineId = null;
    if (JSON.parse(columns[1].MachineId != '') && JSON.parse(columns[1].MachineId != null))
      machineId = columns[1].MachineId;

    let terminalTypeId = null;
    if (JSON.parse(columns[2].TerminalTypeId != '') && JSON.parse(columns[2].TerminalTypeId != null))
      terminalTypeId = columns[2].TerminalTypeId;

    let paymentTypeId = null;
    if (JSON.parse(columns[3].PaymentTypeId != '') && JSON.parse(columns[3].PaymentTypeId != null))
      paymentTypeId = columns[3].PaymentTypeId;

    let productNo = null;
    if (JSON.parse(columns[4].ProductNo != '') && JSON.parse(columns[4].ProductNo != null))
      productNo = columns[4].ProductNo;

    let price = null;
    if (JSON.parse(columns[5].Price != '') && JSON.parse(columns[5].Price != null))
      price = columns[5].Price;

    //set default create from / to date time
    let fromCreateDateTime = moment().format('YYYY-MM-DDT00:00:00');
    let toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59');
    // const getParsedDateTime = (date) => {
    //   let str = `${date.getYear()}-${date.getMonth()}-${date.getDay()}T${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    //   return str
    // }
    if (JSON.parse(columns[6].dateTimeRangeFrom != '') && JSON.parse(columns[6].dateTimeRangeFrom != null))

        // fromCreateDateTime = lastTrxDatetime ? moment(getCorrectTime(lastTrxDatetime, "search")).add(1, "second") : getCorrectTime(columns[6].dateTimeRangeFrom, "search");
      fromCreateDateTime = moment(columns[6].dateTimeRangeFrom).format('YYYY-MM-DDTHH:mm:ssZ');
    // fromCreateDateTime = new Date(columns[6].dateTimeRangeFrom).toISOString()

    if (JSON.parse(columns[6].dateTimeRangeTo != '') && JSON.parse(columns[6].dateTimeRangeTo != null))
      toCreateDateTime = moment(columns[6].dateTimeRangeTo).format('YYYY-MM-DDTHH:mm:ssZ');
    // if (fromCreateDateTime.includes("Invalid") || toCreateDateTime.includes("Invalid")) {
    if (!moment(fromCreateDateTime).isValid() || !moment(toCreateDateTime).isValid()) {
      NotificationManager.error("Wybierz poprawną datę")
      decrementRequests()
      return
    }
    // toCreateDateTime = new Date(columns[6].dateTimeRangeTo).toISOString()

    let machineTags = null;
    try {
      machineTags = filter.activeTags
    } catch (e) {
      console.error(e)
    }

    getTrxs(terminalSerialNo, machineId, terminalTypeId, paymentTypeId, productNo, price, fromCreateDateTime, toCreateDateTime, pageChanged ? filter.pageNo : 1 , filter.rowsPerPage, pageChanged, null, machineTags);
  }

  const filterByDate=(dateA, dateB)=> new Date(dateA) === new Date(dateB) && 0 || new Date(dateA) < new Date(dateB) ? 1 : -1
  const getTrxs = (terminalSerialNo = null, machineId = null, terminalTypeId = null, paymentTypeId = null, productNo = null, price = null, fromCreateDateTime = moment().add(-3, 'd').format('YYYY-MM-DDTHH:mm:ss'), toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59'), pageNo = 1, recordsPerPage = 100, pageChanged = false, isPrevPage, tags) => {
    fetchMssqlApi(`trxs3`, {
      method: 'POST',
      hideNotification: true,
      data: {
        terminalSerialNo,
        machineId: machineId,
        terminalTypeId: terminalTypeId,
        paymentTypeId: paymentTypeId,
        productNo: productNo,
        price: price,
        fromCreateDateTime: fromCreateDateTime,
        toCreateDateTime: toCreateDateTime,
        pageNo,
        recordsPerPage,
        isPrevPage: isPrevPage,
        machineTags: tags,
      },
      showLoader: false,
    }, trxs => {
      pageChanged && resetPage()
      if (isPrevPage) {
        const data = trxs[0]
        if (!data) {
          setTrxs([])
          return
        }
        setTrxs(data.sort(filterByDate))
      } else setTrxs(trxs[0])
      if (pageNo === 1 && trxs.length === 2) {
        // const {TotalReturnedSales, TotalReturnedVend, TotalSuccessedSales, TotalSuccessedVend}
        setSummaryInfo(trxs[1][0])
      }
    })
  }

  const parseTrxForFlatTable = trxs => trxs && trxs.map(i => ({
    "Terminal" : `${i.TerminalTypeName} ${i.SerialNo}`
    , "Automat": i.MachineFullName
    , "Data / czas": i.CreateDateTime
    , "Typ płatności": (() => {
      switch(i.PaymentType) {
        case "Cash":
          return "Gotówka"
        default:
          return "Bezgotówkowo"
      }
    })()
    , "Cena": Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(i.Price)
    , "Nr wyboru": i.ProductNo
    , "Status": (() => {
      switch(i.Status) {
        case "V_S":
        case "A":
          return <span className="badge badge-success">Pozytywna</span>
        case "R_S":
          return <span className="badge badge-danger">Zwrot</span>
        case "T_F":
          return <span className="badge badge-warning">Odmowa</span>
      }
    })()
    , "Nr karty": i.CreditCardNo
  }))

  useEffect(() => {
    setFilterIsDefault(getIsFilterDefault())
  }, [filter.columns])

  useEffect(() => {
    setHeaderData({ text: TRL_Pack.navigation.trxs })
    setFilter(prev => ({...prev, page: 1}))
    //getTrxs();
    search();
    getClients();
  }, [])


  useEffect(() => localStorage.setItem('trxsFilter', JSON.stringify(filter)), [
    filter
  ])
  return (
      trxs && (

          <div className='container-fluid'>
            <div className='w-100 d-flex justify-content-center'>
            </div>
            {filter.visible && (

                <div className='d-flex justify-content-center align-items-center'>
                  <div>
                    <FilterTrx
                        {...{
                          filter,
                          setFilter,
                          columns: filter.columns,
                          resetPage,
                          resetFilter,
                          search,
                          toggleFilter,
                          setIsFlatTable,
                          isFlatTable
                        }}
                    />
                  </div>
                </div>
            )}


            <>
              <div className="overflow-auto">
                <div className=' w-100 justify-content-between ' style={styles.control}>
                  <div style={styles.paginationContainer}>
                    <PaginationEx
                        {...{
                          totalItems: recordsetLength,
                          page: filter.page,
                          handleSwitchPage: handleSwitchPage(),
                          rowsPerPage: filter.rowsPerPage,
                          resetFilter,
                          filterVisibility: filter.visible,
                          rowsCount: trxs && trxs.length,
                        }}
                    />
                    <div className={'w-100 d-flex justify-content-start align-items-center'}>
                      <div className="badge badge-success align-middle ml-1">
                        <div className="fas fa-s fa-credit-card" />
                        {`${Intl.NumberFormat('pl-PL', {
                          style: 'currency',
                          currency: 'PLN',
                        }).format(summaryInfo?.TotalCashlessSuccessedSales || 0)} (${summaryInfo?.TotalCashlessSuccessedVend})`}
                      </div>
                      <div className="badge badge-success align-middle ml-1">
                        <div className="fas fa-s fa-coins" />
                        {`${Intl.NumberFormat('pl-PL', {
                          style: 'currency',
                          currency: 'PLN',
                        }).format(summaryInfo?.TotalCashSuccessedSales || 0)} (${summaryInfo?.TotalCashSuccessedVend})`}
                        {/*{`${Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(summaryInfo?.TotalSuccessedSales || 0)} (${summaryInfo?.TotalSuccessedVend})`}*/}
                      </div>
                      <div className="badge badge-danger align-middle ml-1">
                        {`${Intl.NumberFormat('pl-PL', {
                          style: 'currency',
                          currency: 'PLN',
                        }).format(summaryInfo?.TotalReturnedSales || 0)} (${summaryInfo?.TotalReturnedVend})`}
                        {/*<div className='fas fa-s fa-coins' /> {`${Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(summaryInfo?.TotalCashReturnedSales || 0)} (${summaryInfo?.TotalCashReturnedVend})`}*/}
                      </div>
                      {/*<OverlayTrigger*/}
                      {/*  overlay={*/}
                      {/*    <Tooltip>*/}
                      {/*      <div>*/}
                      {/*        <div className='fas fa-s fa-credit-card' /> {`${Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(summaryInfo?.TotalCashlessSuccessedSales || 0)} (${summaryInfo?.TotalCashlessSuccessedVend})`}*/}
                      {/*        <hr/>*/}
                      {/*        <div className='fas fa-s fa-coins' /> {`${Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(summaryInfo?.TotalCashSuccessedSales || 0)} (${summaryInfo?.TotalCashSuccessedVend})`}*/}
                      {/*      </div>*/}
                      {/*    </Tooltip>*/}
                      {/*  }*/}
                      {/*>*/}
                      {/*  <div className="badge badge-success align-middle ml-1">{`${Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(summaryInfo?.TotalSuccessedSales || 0)} (${summaryInfo?.TotalSuccessedVend})`}</div>*/}
                      {/*</OverlayTrigger>*/}
                      {/*<OverlayTrigger*/}
                      {/*  overlay={*/}
                      {/*  <Tooltip>*/}
                      {/*    <div>*/}
                      {/*      <div className='fas fa-s fa-credit-card' /> {`${Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(summaryInfo?.TotalCashlessReturnedSales || 0)} (${summaryInfo?.TotalCashlessReturnedVend})`}*/}
                      {/*      <hr/>*/}
                      {/*      <div className='fas fa-s fa-coins' /> {`${Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(summaryInfo?.TotalCashReturnedSales || 0)} (${summaryInfo?.TotalCashReturnedVend})`}*/}
                      {/*    </div>*/}
                      {/*  </Tooltip>*/}
                      {/*  }*/}
                      {/*>*/}
                      {/*  <div className="badge badge-danger align-middle ml-1">{`${Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(summaryInfo?.TotalReturnedSales || 0)} (${summaryInfo?.TotalReturnedVend})`}</div>*/}
                      {/*</OverlayTrigger>*/}
                    </div>
                  </div>
                  <div style={styles.buttonContainer}>
                    <button className={`d-inline fas fa-filter fa-s ${filter.visible ? 'text-primary' : 'text-secondary'} btn align-content-center button-outline-none`} onClick={toggleFilter}  style={{height: "25px"}}>
                    </button>
                    {(!filterIsDefault || filter.visible) && (
                        <button className={`d-inline fas fa-times-circle fa-s btn align-content-center`} onClick={resetFilter} style={{height: "25px", color: "#dc3545"}}>
                        </button>
                    )}
                    {/*{setIsFlatTable && (*/}
                    {/*    <button className={`fa-s btn align-content-center`} onClick={() => {*/}
                    {/*      setIsFlatTable(!isFlatTable)*/}
                    {/*      document.querySelector("section").scroll(0, 0)*/}
                    {/*      */}
                    {/*    }} style={{ height: '3vh', backgroundColor: 'transparent' }} >*/}
                    {/*      <i*/}
                    {/*          className={`fas fa-toggle-${isFlatTable ? `on text-primary` : `off text-secondary`} fa-lg`}*/}
                    {/*      />*/}
                    {/*    </button>*/}
                    {/*)}*/}
                  </div>
                  <div style={styles.reportContainer}>
                    <ReportBtn
                        clickFnc={export97}
                        state={rep97}
                        setState={setRep97}
                        overlayText={<div><b>Typ raportu:<br />Udział procentowy koszyków i promocji [DZ97]</b><br /><br />Raport dostępny tylko dla automatów wyposażonych w płytę główną R24 VMC<br /><br /></div>}
                        btnColor='light'
                        permissionName={"VD_REPORT_97"}
                    />
                    <ReportBtn
                        clickFnc={export85}
                        state={rep85}
                        setState={setRep85}
                        overlayText={(<div><b>Typ raportu:<br />Transakcje - sprzedaż - wg maszyn i produktów (raport rozszerzony) [DZ85]</b><br /><br />Raport zawiera transakcje gotówkowe oraz bezgotówkowe (w tym BLIK) zgrupowanie wg automatów oraz produktów. <br /><br />Zawiera informacje rozszerzone o przeliczone wartości VAT, EAN.<br /><br /></div>)}
                        btnColor='danger'
                        permissionName={"VD_REPORT_85"}
                    />
                    <ReportBtn
                        clickFnc={export84}
                        state={rep84}
                        setState={setRep84}
                        overlayText={(<div><b>Typ raportu:<br />Transakcje - sprzedaż (raport rozszerzony) [DZ84]</b><br /><br />Raport zawiera transakcje gotówkowe oraz bezgotówkowe (w tym BLIK) wraz ze statusem transakcji. <br /><br />Zawiera informacje rozszerzone o przeliczone wartości VAT, EAN, nr karty oraz podsumowanie po stawkach VAT.<br /><br /></div>)}
                        btnColor='primary'
                        permissionName={"VD_REPORT_84"}
                    />
                    <ReportBtn
                        clickFnc={export83}
                        state={rep83}
                        setState={setRep83}
                        overlayText={(<div>
                          <b>Typ raportu:<br />Transakcje - szczegóły [DZ83]</b><br /><br />Raport zawiera wszystkie transakcje gotówkowe oraz bezgotówkowe (w tym BLIK).
                        </div>)}
                        btnColor='secondary'
                        permissionName={"VD_REPORT_83"}
                    />
                    <ReportBtn
                        clickFnc={export76}
                        state={rep76}
                        setState={setRep76}
                        overlayText={(<div>
                          <b>Typ raportu:<br />Transakcje - sprzedaż [DZ76]</b><br /><br />Raport zawiera transakcje gotówkowe oraz bezgotówkowe (w tym BLIK) zakończone poprawnym wydaniem.
                        </div>)}
                        btnColor='success'
                        permissionName={"VD_REPORT_76"}
                    />
                    <ReportBtn
                        clickFnc={exportSumByPaymentType}
                        state={sumByPaymentType}
                        setState={setSumByPaymentType}
                        overlayText={(<div>
                          Export danych z listy pogrupowanych wg typu płatności [DZ89]
                        </div>)}
                        btnColor='warning'
                        permissionName={"VD_REPORT_89"}
                    />
                    <ReportBtn
                        clickFnc={exportSum}
                        state={repSum}
                        setState={setRepSum}
                        overlayText={(<div>
                          Export danych z listy pogrupowanych wg automatu i produktów [DZ90]
                        </div>)}
                        btnColor='dark'
                        permissionName={"VD_REPORT_90"}
                    />
                    <ReportBtn
                        clickFnc={exportList}
                        state={repList}
                        setState={setRepList}
                        overlayText={(<div>
                          Export danych z listy [DZ91]
                        </div>)}
                        btnColor='info'
                        permissionName={"VD_REPORT_91"}
                    />
                  </div>
                </div>
                {trxs.length ? (
                    <section className="card-body overflow-auto p-0" style={{maxHeight: "80vh", maxWidth: "95vw"}}>
                      {!isFlatTable ? (
                          <table className="table table-striped border">
                            <thead>
                            <tr>
                              {localStorage.getItem("clientId") === "console" && (
                                  <th><div className='d-flex flex-column justify-content-center align-items-center'>
                                    <div className="text-nowrap">Klient</div>
                                  </div></th>
                              )}
                              <th><div className='d-flex flex-column justify-content-center align-items-center'>
                                <div className="text-nowrap">Terminal</div></div></th>
                              <th><div className='d-flex flex-column justify-content-center align-items-center'>
                                <div className="text-nowrap">Automat</div></div></th>
                              <th><div className='d-flex flex-column justify-content-center align-items-center'>
                                <div className="text-nowrap">Data / czas</div></div></th>
                              <th><div className='d-flex flex-column justify-content-center align-items-center'>
                                <div className="text-nowrap">Typ / Cena / Nr wyboru / Status</div>
                                <div className='d-flex flex-row justify-content-center align-items-center'>
                                  <div className="badge badge-success align-middle">Pozytywna</div><div className="badge badge-danger align-middle ml-1">Zwrot</div>
                                  <div className="badge badge-warning align-middle ml-1">Odmowa</div></div>
                              </div></th>
                              <th><div className='d-flex flex-column justify-content-center align-items-center'>
                                <div className="text-nowrap">Nr karty</div></div></th>
                            </tr>
                            </thead>
                            <tbody>

                            {trxs && trxs
                                .map((trx, idx) => (
                                    <tr key={idx}>
                                      {localStorage.getItem("clientId") === "console" && (
                                          <td><div className='d-flex flex-column justify-content-center align-items-center'>
                                            <div className="text-nowrap">{trx.CustomerName}</div>
                                          </div>
                                          </td>
                                      )}
                                      <td>
                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                          <div className='d-flex flex-row justify-content-center align-items-center'>
                                            <div className="text-nowrap">{trx.TerminalTypeName} {trx.SerialNo}</div>

                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                          <div className='d-flex flex-row justify-content-center align-items-center'>
                                            <div className="text-wrap">{trx.MachineFullName}</div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                          <div className='d-flex flex-row justify-content-center align-items-center align-middle'>
                                            <div className="text-nowrap">{trx.CreateDateTime}</div>
                                          </div>
                                          <div className="text-nowrap">
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                          <div className='d-flex flex-row justify-content-center align-items-center'>
                                            {(trx.PaymentType && trx.PaymentType.indexOf('Cash') !== -1) &&  (<h6><div className='fas fa-s fa-coins' /> </h6>)}
                                            {(trx.PaymentType && trx.PaymentType.indexOf('Credit Card') !== -1) && trx.Price !=0.00 &&  (<h6><div className='fas fa-s fa-credit-card' /> </h6>)}
                                            {(trx.PaymentType && trx.PaymentType.indexOf('Credit Card') !== -1) && trx.Price ===0.00 &&  (<h6><div className='fas fa-s fa-creative-commons-zero' /> </h6>)}
                                            {(trx.PaymentType && trx.PaymentType.indexOf('Prepaid Credit') !== -1) && (<h6><div className='fas fa-s fa-credit-card' /> </h6>)}

                                            {(trx.Status && trx.Status.indexOf('V_S') !== -1 || trx.Status.indexOf('A') !== -1) && (<h6><div className="badge badge-success align-middle m-1">{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(trx.Price)} ({trx.ProductNo})</div></h6>)}
                                            {(trx.Status && trx.Status.indexOf('R_S') !== -1) && (<h6><div className="badge badge-danger align-middle m-1">{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(trx.Price)} ({trx.ProductNo})</div></h6>)}
                                            {(trx.Status && trx.Status.indexOf('T_F') !== -1) && (<h6><div className="badge badge-warning align-middle m-1">{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(trx.Price)} ({trx.ProductNo})</div></h6>)}

                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                          <div className='d-flex flex-column justify-content-center align-items-center'>
                                            <div className="text-nowrap">{trx.CreditCardNo}</div>
                                            <div className="text-nowrap">{trx.Info}</div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                ))}
                            </tbody>
                          </table>
                      ) : (
                          <FlatTable headers={["Terminal", "Automat","Data / czas", "Typ płatności", "Cena", "Nr wyboru", "Status", "Nr karty"]} data={parseTrxForFlatTable(trxs)} />
                      )}
                    </section>
                ) : (
                    <NoResults />
                )}

              </div>
            </>

          </div>
      )
  )
}

export default Trxs;